import React, {useEffect, useState} from 'react'
import {Button, Card, Col, Row} from 'react-bootstrap'
import CustomDatePicker from '../../custom/DateRange/DatePicker'
import {KTSVG} from '../../../_seller/helpers'
import {useNavigate} from 'react-router-dom'
import {
  OrderCancelled,
  OrderDelivered,
  OrderOutForDelivery,
  OrderProcessed,
  OrderSubmitted,
  PAGE_LIMIT,
} from '../../../utils/constants'
import {getKey, setKey} from '../../../Global/history'
import {listInterTransferOrder} from '../../../utils/storeString'
import {useAuth} from '../auth'
import APICallService from '../../../api/apiCallService'
import {interTransferRequest} from '../../../api/apiEndPoints'
import {useDebounce} from '../../../utils/useDebounce'
import Method from '../../../utils/methods'
import Loader from '../../../Global/loader'
import Pagination from '../../../Global/pagination'

const InterTransferRequest = () => {
  const {currentUser} = useAuth()
  const [fetchLoader, setFetchLoader] = useState(true)
  const [dataFetched, setDataFetched] = useState(false)
  const [orders, setOrders] = useState<any>([])
  const [page, setPage] = useState<any>(getKey(listInterTransferOrder.page) || 1)
  const [totalRecords, setTotalRecords] = useState(0)
  const [loading, setLoading] = useState(false)
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listInterTransferOrder.limit) || PAGE_LIMIT
  )
  const [startDate, setStartDate] = useState<any>(
    getKey(listInterTransferOrder.filterStartDate)
      ? new Date(getKey(listInterTransferOrder.filterStartDate)?.toString() || '')
      : null
  )
  const [endDate, setEndDate] = useState<any>(
    getKey(listInterTransferOrder.filterEndDate)
      ? new Date(getKey(listInterTransferOrder.filterEndDate)?.toString() || '')
      : null
  )
  const [search, setSearch] = useState<string>(
    getKey(listInterTransferOrder.search)?.toString() || ''
  )
  const navigate = useNavigate()
  console.log('startDate', startDate)
  console.log('endDate', endDate)
  const fetchDetails = async (
    pageNo: number,
    limit: number,
    search?: string,
    fromDate?: string,
    toDate?: string
  ) => {
    setLoading(true)
    setFetchLoader(true)
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      listType: 1,
      sortKey: '_createdAt',
      sortOrder: -1,
      needCount: true,
      searchTerm: search ? search : '',
      fromDate: fromDate ? Method.convertDateToFormat(fromDate, 'YYYY-MM-DD') : '',
      toDate: toDate ? Method.convertDateToFormat(toDate, 'YYYY-MM-DD') : '',
    }
    let apiService = new APICallService(interTransferRequest.interTransferList, params, {
      id: currentUser?.business.profile,
    })
    let response: any = await apiService.callAPI()
    if (response.records) {
      setTotalRecords(response.total || 0)
      setOrders(response.records)
    }
    setFetchLoader(false)
    setLoading(false)
  }
  const handleCurrentPage = async (val: number) => {
    setFetchLoader(true)
    setPage(val)
    setKey(listInterTransferOrder.page, val)
    await fetchDetails(val, pageLimit, search, startDate, endDate)
    setFetchLoader(false)
  }

  const handleNextPage = async (val: number) => {
    setFetchLoader(true)
    setPage(val + 1)
    setKey(listInterTransferOrder.page, val + 1)
    await fetchDetails(val + 1, pageLimit, search, startDate, endDate)
    setFetchLoader(false)
  }

  const handlePreviousPage = async (val: number) => {
    setFetchLoader(true)
    setPage(val - 1)
    setKey(listInterTransferOrder.page, val - 1)
    await fetchDetails(val - 1, pageLimit, search, startDate, endDate)
    setFetchLoader(false)
  }

  const handlePageLimit = async (event: any) => {
    setFetchLoader(true)
    setPage(1)
    setKey(listInterTransferOrder.page, 1)
    setKey(listInterTransferOrder.limit, parseInt(event.target.value))
    await fetchDetails(1, parseInt(event.target.value), search, startDate, endDate)
    setPageLimit(parseInt(event.target.value))
    setFetchLoader(false)
  }

  const debouncedOnChange = useDebounce(fetchDetails, 200)
  const handleSearch = async (value: string) => {
    setSearch(value)
    setFetchLoader(true)
    setPage(1)
    await debouncedOnChange(1, pageLimit, value, startDate, endDate)
    setFetchLoader(false)
  }

  const handleDateFilter = async (event: any) => {
    setStartDate(event[0])
    setEndDate(event[1])
    setTotalRecords(0)
    setOrders([])
    setFetchLoader(true)
    setPage(1)
    setKey(listInterTransferOrder.page, 1)
    const startDateFormatted = event[0] ? Method.convertDateToFormat(event[0], 'YYYY-MM-DD') : ''
    const endDateFormatted = event[1] ? Method.convertDateToFormat(event[1], 'YYYY-MM-DD') : ''
    await fetchDetails(1, pageLimit, search, startDateFormatted, endDateFormatted)
    setFetchLoader(false)
  }

  useEffect(() => {
    setFetchLoader(true)
    fetchDetails(page, pageLimit, search, startDate, endDate)
    setDataFetched(true)
    setFetchLoader(false)
  }, [])

  return (
    <div className='layout-padding my-7'>
      <Row className='variant-categories custom-tabContainer'>
        <Col lg={12}>
          <Row className='align-items-center mb-7'>
            <Col sm>
              <h1 className='fs-22 fw-bolder mb-sm-0 mb-5'>Inter transfer request</h1>
            </Col>
          </Row>
        </Col>
        <Col lg={12}>
          <Card className='bg-light border mb-7'>
            <Card.Body className='pb-3'>
              <Row className='align-items-center justify-content-between'>
                <Col lg='4' className='mb-5'>
                  <div className='d-flex align-items-center position-relative'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen021.svg'
                      className='svg-icon-3 position-absolute ms-3'
                    />
                    <input
                      type='text'
                      id='kt_filter_search'
                      className='form-control form-control-white form-control-lg min-h-60px ps-10'
                      placeholder='Search by transfer ID ....'
                      onChange={(event) => handleSearch(event.target.value.trimStart())}
                    />
                  </div>
                </Col>
                <Col lg={6} className='mb-5'>
                  <div className='d-flex flex-wrap flex-row justify-content-lg-end align-items-center'>
                    <label htmlFor='' className='fs-16 fw-500 me-5'>
                      Filter by dates
                    </label>
                    <div className='mw-lg-300px min-w-lg-300px'>
                      <CustomDatePicker
                        className='form-control bg-white min-h-60px fs-16 fw-bold text-dark min-w-md-288px min-w-225px'
                        selected={startDate}
                        onChange={handleDateFilter}
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat='dd/MM/yyyy'
                        showFullMonthYearPicker={true}
                        maxDate={new Date()}
                        isClearable={true}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          {loading ? (
            <div className='border border-r10px mb-6'>
              <div className='d-flex justify-content-center text-center align-items-center min-h-160px px-3'>
                <Loader loading={loading} />
              </div>
            </div>
          ) : orders && orders.length ? (
            <>
              <Card className='border border-r10px'>
                <Card.Body className='p-0'>
                  <div className='table-responsive'>
                    <table className='table table-rounded table-row-bordered align-middle gs-7 gy-6'>
                      <thead>
                        <tr className='fw-bold fs-16 fw-600 text-dark border-bottom align-middle'>
                          <th className='min-w-md-175px'>Transfer Date & ID</th>
                          <th className='min-w-md-100px'>Quantity</th>
                          <th className='min-w-md-125px'>Total amount</th>
                          <th className='min-w-md-125px text-end'></th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((orderVal: any, index: number) => (
                          <tr>
                            <td>
                              <span className='text-dark fw-500 fs-15 d-block'>
                                {Method.convertDateToDDMMYYYYHHMM(orderVal._createdAt, '-')}
                              </span>
                              <span className='text-gray fw-500 fs-15 d-block'>
                                {orderVal.refKey}
                              </span>
                            </td>
                            <td>
                              <span className='fs-15 fw-500'>
                                {' '}
                                {orderVal.transferredQuantities + ' items'}
                              </span>
                            </td>
                            <td>
                              <span className='fs-15 fw-500'>
                                {' '}
                                {'TSh ' + Method.getGeneralizedAmount(orderVal.payment.totalCharge)}
                              </span>
                            </td>
                            <td className='text-end'>
                              <Button
                                className='fs-14 fw-600'
                                onClick={() => {
                                  let currentScrollY = window.scrollY
                                  setKey(listInterTransferOrder.scrollPosition, currentScrollY)
                                  setKey(listInterTransferOrder.search, search, false)
                                  setKey(listInterTransferOrder.filterStartDate, startDate, false)
                                  setKey(listInterTransferOrder.filterEndDate, endDate, false)
                                  navigate('/inter-transfer-request/inter-transfer-detail', {
                                    state: orderVal._id,
                                  })
                                }}
                              >
                                View details
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
              {!loading && orders && orders.length ? (
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={page}
                  handleCurrentPage={handleCurrentPage}
                  handleNextPage={handleNextPage}
                  handlePreviousPage={handlePreviousPage}
                  handlePageLimit={handlePageLimit}
                  pageLimit={pageLimit}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <Card className='border border-r10px'>
              <Card.Body>
                <div className='d-flex justify-content-center align-items-center min-h-150px'>
                  No Records found
                </div>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default InterTransferRequest
