export const listAllProducts = {
  page: 'listAllProductsPage',
  limit: 'listAllProductsLimit',
  scrollPosition: 'listAllProductsScrollPosition',
  search: 'listAllProsuctsSearch',
  filterCategories: 'listAllProductsFilterCategories',
  filterProductState: 'listAllProductsFilterProductState'
}

export const listProductDetails = {
  page: 'listProductDetailsPage',
  limit: 'listProductDetailsLimit',
  filterInventory: 'listProductDetailsFilterInventory',
  currentTab: 'listProductDetailsCurrentTab',
  filterStartDate: 'listOrdersDeliveryFilterStartDate',
  filterEndDate: 'listOrdersDeliveryFilterEndDate'
}

export const listGoodsRequest = {
  page: 'listGoodsRequestPage',
  limit: 'listGoodsRequestLimit',
  currentTab: 'listGoodsRequestCurrentTab',
  scrollPosition: 'listGoodsRequestScrollPosition',
  search: 'listGoodsRequestSearch'
}

export const listOrdersDelivery = {
  page: 'listOrdersDeliveryPage',
  limit: 'listOrdersDeliveryLimit',
  currentTab: 'listOrdersDeliveryCurrentTab',
  scrollPosition: 'listOrdersDeliveryScrollPosition',
  search: 'listOrdersDeliverySearch',
  filterStartDate: 'listOrdersDeliveryFilterStartDate',
  filterEndDate: 'listOrdersDeliveryFilterEndDate'
}

export const listInventory = {
  page: 'listInventoryPage',
  limit: 'listInventoryLimit',
  scrollPosition: 'listInventoryScrollPosition',
  search: 'listInventorySearch',
  filterProductState: 'listInventoryFilterProductState',
  filterExpiryPeriod: 'listInventoryFilterExpiryPeriod'
};

export const listAdvertisement = {
  page: 'listAdvertisementPage',
  limit: 'listAdvertisementLimit',
  currentTab: 'listAdvertisementCurrentTab',
  scrollPosition: 'listAdvertisementScrollPosition',
};

export const listInterTransferOrder = {
  page: 'listInterTransferPage',
  limit: 'listInterTransferLimit',
  currentTab: 'listInterTransferCurrentTab',
  scrollPosition: 'listInterTransferScrollPosition',
  search: 'listInterTransferSearch',
  filterStartDate: 'listInterTransferFilterStartDate',
  filterEndDate: 'listInterTransferFilterEndDate'
}