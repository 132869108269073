import React, {useEffect, useState} from 'react'
import APICallService from '../../../api/apiCallService'
import {interTransferRequest} from '../../../api/apiEndPoints'
import {useLocation} from 'react-router-dom'
import {useAuth} from '../auth'
import Loader from '../../../Global/loader'
import {Card, Col, Row} from 'react-bootstrap'
import Method from '../../../utils/methods'
import {
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  OrderCard,
  OrderCash,
  OrderMPesa,
  OrderTigoPesa,
  Piece,
} from '../../../utils/constants'

const InterTransferDetail = () => {
  const {currentUser} = useAuth()
  const {state}: any = useLocation()
  const [fetchLoader, setFetchLoader] = useState(true)
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState<any>()
  useEffect(() => {
    ;(async () => {
      setFetchLoader(true)
      await fetchDetails()
      setFetchLoader(false)
    })()
  }, [])
  console.log('currentUser', currentUser)
  const fetchDetails = async () => {
    let apiService = new APICallService(interTransferRequest.transferDetail, {
      id: currentUser?.business.profile,
      orderId: state,
    })
    let response = await apiService.callAPI()
    setDetails(response.record)
  }
  console.log('details', details)
  return (
    <>
      <div className='layout-padding my-7'>
        <Row className='g-8'>
          {!fetchLoader ? (
            <>
              {' '}
              <Col md={12}>
                <Row className='align-items-center g-3'>
                  <Col xs>
                    <h1 className='fs-22 fw-bolder mb-0'>Transfer details</h1>
                  </Col>
                </Row>
              </Col>
              {/* <Col md={6}>
                  <Card className='border'>
                    <Card.Header className='bg-light align-items-center'>
                      <Card.Title className='fs-22 fw-bolder'>Delivery address</Card.Title>
                    </Card.Header>
                    <Card.Body className='pb-3'>
                      <Row className='mb-5'>
                        <Col md={5} xs={4}>
                          <label className=' fs-16 fw-500 text-dark'>District:</label>
                        </Col>
                        <Col md={7} xs={8}>
                          <span className='fw-bold fs-16 fw-600 text-dark'>
                            {details.address.districtName}
                          </span>
                        </Col>
                      </Row>
                      <Row className='mb-5'>
                        <Col md={5} xs={4}>
                          <label className=' fs-16 fw-500 text-dark'>City:</label>
                        </Col>
                        <Col md={7} xs={8}>
                          <span className='fw-bold fs-16 fw-600 text-dark'>
                            {details.address.city}
                          </span>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col> */}
              <Col md={12}>
                <Card className='border'>
                  <Card.Header className='bg-light align-items-center'>
                    <Card.Title className='fs-22 fw-bolder'>ID: {details.refKey}</Card.Title>
                  </Card.Header>
                  <Card.Body className='pb-3'>
                    <Row className='mb-5'>
                      <Col md={5} xs={4}>
                        <label className=' fs-16 fw-500 text-dark'>Transfer Placed on:</label>
                      </Col>
                      <Col md={7} xs={8}>
                        <span className='fw-bold fs-16 fw-600 text-dark'>
                          {Method.convertDateToFormat(details._createdAt, 'DD/MM/YYYY - HH:mm')}
                        </span>
                      </Col>
                    </Row>
                    {/* <Row className='mb-5'>
                      <Col md={5} xs={4}>
                        <label className=' fs-16 fw-500 text-dark'>Payment Method:</label>
                      </Col>
                      <Col md={7} xs={8}>
                        <span className='fw-bold fs-16 fw-600 text-dark'>
                          {' '}
                          {details.payment.paymentMode === OrderCash ? 'Cash' : ''}
                          {details.payment.paymentMode === OrderTigoPesa ? 'Tigo pesa' : ''}
                          {details.payment.paymentMode === OrderMPesa ? 'MPesa' : ''}
                          {details.payment.paymentMode === OrderCard ? 'Card' : ''}
                        </span>
                      </Col>
                    </Row> */}
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12}>
                <Card className='border border-r10px'>
                  <Card.Body className='p-0'>
                    <div className='table-responsive'>
                      <table className='table table-rounded table-row-bordered align-middle gs-9 gy-6 mb-0'>
                        <thead>
                          <tr className='fw-bold fs-16 fw-600 text-dark border-bottom align-middle'>
                            <th className='w-md-475px min-w-275px'>Product name</th>
                            <th className='min-w-md-150px'>Unit price</th>
                            <th className='w-md-125px'>Qty. & Type</th>
                            <th className='w-md-125px'>Tax(%)</th>
                            <th className='min-w-md-100px '>Tax amount</th>
                            <th className='min-w-md-100px text-left'>Total amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {details.variants.map((val: any) => {
                            return (
                              <tr>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='symbol symbol-50px border'>
                                      <span
                                        className='symbol-label bgi-contain'
                                        style={{
                                          backgroundImage: `url(${val.variant.media[0].url})`,
                                        }}
                                      ></span>
                                    </div>
                                    <div className='d-flex flex-column ms-5'>
                                      <span className='text-dark fw-600 fs-15 d-block'>
                                        {val.variant.title.replace(/\s*\)\s*/g, ')')}
                                      </span>
                                      <span className='text-dark fw-500 fs-15 d-block'>
                                        {val.variant.product.business.name}
                                      </span>
                                    </div>
                                  </div>
                                </td>{' '}
                                <td>
                                  <span className='fs-15 fw-500'>
                                    TSh {Method.getGeneralizedAmount(val.amount)}
                                  </span>
                                </td>
                                <td>
                                  <span className='fs-15 fw-500'>
                                    {val.stockCount} X{' '}
                                    {val.quantityType === CartonWithDozens ||
                                    val.quantityType === CartonWithPieces
                                      ? 'Cartons'
                                      : ''}
                                    {val.quantityType === Dozen ? 'Dozen' : ''}
                                    {val.quantityType === Piece ? 'Piece' : ''}
                                  </span>
                                </td>
                                <td>
                                  <span className='fs-15 fw-500'>
                                    {val.productTax ? val.productTax + '%' : '0%'}
                                  </span>
                                </td>
                                <td className='text-end'>
                                  <span className='fs-15 fw-500 '>
                                    TSh{' '}
                                    {val.productTax
                                      ? Method.getGeneralizedAmount(
                                          val.totalAmount - val.beforeTaxAmount
                                        )
                                      : '0'}
                                  </span>
                                </td>
                                <td className='text-end'>
                                  <span className='fs-15 fw-500'>
                                    TSh {Method.getGeneralizedAmount(val.totalAmount)}
                                  </span>
                                </td>
                              </tr>
                            )
                          })}
                          <tr>
                            <td colSpan={5} className='fs-16 fw-600 text-end'>
                              Subtotal:
                            </td>
                            <td colSpan={1} className='fs-16 fw-600 text-end'>
                              TSh{' '}
                              {Method.getGeneralizedAmount(
                                details.variants.reduce((p: any, c: any) => {
                                  let actualCharge = c.stockCount * c.originalAmount
                                  return p + actualCharge
                                }, 0)
                              )}
                            </td>
                          </tr>
                          {details?.payment?.discounts?.length ? (
                            <>
                              <tr>
                                <td colSpan={5} className='fs-16 fw-600 text-end'>
                                  UTrade special discount:
                                </td>
                                <td colSpan={1} className='fs-16 fw-600 text-end'>
                                  TSh -{' '}
                                  {Method.getGeneralizedAmount(
                                    details?.payment?.discounts[0]?.value
                                  )}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                          {details.variants.reduce((p: any, c: any) => {
                            let actualCharge = c.stockCount * c.originalAmount
                            let discountedCharge = c.stockCount * c.amount
                            return p + actualCharge - discountedCharge
                          }, 0) > 0 ? (
                            <tr>
                              <td colSpan={5} className='fs-16 fw-600 text-end'>
                                Product Discount:
                              </td>
                              <td colSpan={1} className='fs-16 fw-600 text-end'>
                                - TSh{' '}
                                {Method.getGeneralizedAmount(
                                  details.variants.reduce((p: any, c: any) => {
                                    let actualCharge = c.stockCount * c.originalAmount
                                    let discountedCharge = c.stockCount * c.amount
                                    return p + actualCharge - discountedCharge
                                  }, 0)
                                )}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          {details.payment.taxes.length ? (
                            <tr>
                              <td colSpan={5} className='fs-16 fw-600 text-end'>
                                {' '}
                                Tax
                                {details.payment.taxes[0].value === 2 ? '(2%)' : ''}:
                              </td>
                              <td colSpan={1} className='fs-16 fw-600 text-end'>
                                TSh{' '}
                                {Method.getGeneralizedAmount(
                                  details.payment.taxes.reduce(
                                    (p: any, c: any) => p + c.appliedCharge,
                                    0
                                  )
                                )}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td colSpan={5} className='fs-22 fw-bold text-end'>
                              Grand Total:
                            </td>
                            <td colSpan={1} className='text-dark fs-22 fw-bold text-end'>
                              TSh {Method.getGeneralizedAmount(details.payment.totalCharge)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>{' '}
            </>
          ) : (
            <>
              <Col lg={12}>
                <div className='d-flex justify-content-center'>
                  <Loader loading={fetchLoader} />
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>
    </>
  )
}

export default InterTransferDetail
