import {useEffect, useRef} from 'react'
type Timer = ReturnType<typeof setTimeout>
type SomeFunction = (...args: any[]) => void
export function useDebounce<Func extends SomeFunction>(func: Func, delay = 500) {
  const timer = useRef<Timer>()
  useEffect(() => {
    return () => {
      if (!timer.current) return
      clearTimeout(timer.current)
    }
  }, [])
  const debouncedFunction = ((...args) => {
    const newTimer = setTimeout(() => {
      func(...args)
    }, delay)
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = newTimer
  }) as Func

  return debouncedFunction
}
